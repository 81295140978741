import React, {useState} from 'react';
import SimpleModal from '~/components/SimpleModal';
import Button from '~/shared/Btn'
import createForm from '~/shared/Form'
import {ModalContent } from '~/styles/confort/login/style'
import yup from '~/utils/yup'
import TextInput from '~/shared/TextInput';
import Spinner from "~/shared/Spinner";
import Title from '~/shared/Title';
import Password from "~/services/password";
import { toast } from "react-toastify";


const schema = yup.object().shape({
    email: yup.string().email().required(),
})

const [Field, Form] = createForm({
    email: {
        component: TextInput,
        placeholder: 'E-mail'
    }
}, { validationSchema: schema },
)


const PassWordModal = ({show, onHide}) => {

    const [hasError, setHasError] = useState(false)
    const [isEmailSend, setIsEmailSend] = useState(false)

    const changePassword = async ({email}) => {
        try {
            setHasError(false)
            const res = await Password.getPassword(email);

            if (res.status === 200) {
                setIsEmailSend(true)
                return toast.success("Um email foi enviado ao destinatário com as instruções seguintes");
            }
        } catch (e) {
            setHasError(true)
            return toast.error(`Email não foi identificado.`);
        }
    };

  return (
    <>
        <SimpleModal
            show={show}
            closeFn={() => {
                onHide()
                setIsEmailSend(false)
                setHasError(false)
                }
            }
            size='small'
        >
            <ModalContent >
                {
                    !isEmailSend ? (
                        <>
                            <Title level='3'>Esqueceu a senha?</Title>

                            <p>Para recuperar seu acesso, preencha o campo com o e-mail cadastrado em sua conta Fotop Cliente</p>

                            <Form
                                onSubmit={changePassword}
                                renderButton={({ loading }) => (
                                    <div style={{ marginTop: '1rem' }}>
                                        <Button block type="submit">
                                            {loading ? <Spinner /> : "Enviar"}
                                        </Button>
                                    </div>
                                )}
                            >
                                <Field name='email'/>
                                {hasError ? <strong>*E-mail não indentificado</strong> : ""}
                            </Form>

                            <span>
                            Caso não tenha acesso ao e-mail cadastrado, entre em contato com o nosso Atendimento
                            </span>
                        </>
                    ) : (
                        <>
                            <Title level='3'>Enviado!</Title>

                            <p>As informações para sua alteração de senha foram enviadas para o e-mail informado</p>
                        </>
                    )
                }
            </ModalContent>
        </SimpleModal>
    </>
  )
}

export default PassWordModal;
