import styled from "styled-components";

const Title = styled.h6`
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0;
    border-bottom: 1px solid #B7B7B7;
`;

const Header = ({ children }) => {
    return (
        <div style={{ padding: '10px 17px' }}>
            <Title>
                {children}
            </Title>
        </div>
    )
};

export default Header;
