import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import Spinner from "~/shared/Spinner";

export type ButtonVariantTypes = "primary" | "secondary" | "terciary" | "danger" | "warning" | "success";

type SystemButtonProps = {
    variant?: ButtonVariantTypes;
    block?: boolean;
    small?: boolean;
    loading?: boolean;
    round?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonContainerProps = {
    variant: ButtonVariantTypes;
    $round: boolean;
};

const ButtonContainer = styled.button<ButtonContainerProps>`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 0.375rem 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: ${(props) => (props.$round ? "100%" : "4px")};
    min-height: 40px;
    font-weight: bold;
    ${(props) =>
        props.$round
            ? `
        height: 40px;
        width: 40px;
        padding: 0;

    `
            : ""}

    color: ${(props) => props.theme.color[props.variant].foreground};
    background-color: ${(props) => props.theme.color[props.variant].background};
    border-bottom: 2px solid ${(props) => props.theme.color[props.variant].accent};

    &:hover {
        color: ${(props) => props.theme.color[props.variant].variations.lighter.foreground};
        background-color: ${(props) => props.theme.color[props.variant].variations.lighter.background};
        border-color: ${(props) => props.theme.color[props.variant].variations.lighter.accent};
    }

    &:active {
        color: ${(props) => props.theme.color[props.variant].variations.darker.foreground};
        background-color: ${(props) => props.theme.color[props.variant].variations.darker.background};
        border-color: ${(props) => props.theme.color[props.variant].variations.darker.accent};
    }

    &:disabled {
        color: #fff;
        background-color: #b8b5b5;
        border-color: #b8b5b5;
        opacity: 0.45;
        cursor: not-allowed;
    }

    &.block {
        width: 100%;
        display: block;
    }

    &.small {
        padding: 0.25rem 0.5rem;
        font-size: 0.775rem;
        line-height: 1.5;
        min-height: auto;
    }

    &.loading svg {
        width: 24px;
        height: 24px;
    }

    &.small.loading svg {
        width: 21px;
        height: 21px;
    }
`;

const Button: React.FC<SystemButtonProps> = ({
    variant = "primary",
    block = false,
    small = false,
    loading = false,
    round = false,
    children,
    ...props
}) => {
    const classes = [block ? "block" : "", small ? "small" : "", loading ? "loading" : ""];

    return (
        <ButtonContainer
            type="button"
            variant={variant ?? "primary"}
            $round={round}
            {...props}
            className={`${classes.join(" ")} ${props?.className ?? ""}`}
        >
            {loading ? <Spinner /> : children}
        </ButtonContainer>
    );
};

export const SubmitButton: React.FC<SystemButtonProps> = ({ children, ...props }) => {
    return (
        <Button {...props} type="submit">
            {children}
        </Button>
    );
};

export default Button;
