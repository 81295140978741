import { FormGroup } from "~/styles/FormGroup";

type TextInputProps = {
    name: string;
    label?: string;
    onChange: (name: string, value: string) => void;
    onBlur?: (name: string, value: string) => void;
    value?: string;
    error?: string;
    required?: boolean;
    description?: string;
    maxLength?: number;
    Template?: any;
    [key: string]: any;
};

const TextInput = ({
    name,
    label,
    onChange,
    onBlur = null,
    required = false,
    value = "",
    error = "",
    description = "",
    maxLength = undefined,
    Template,
    ...props
}: TextInputProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(name, value);
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!onBlur) {
            return;
        }

        const { name, value } = e.target;
        onBlur(name, value);
    };

    const ContainerComponent = Template || FormGroup;

    return (
        <ContainerComponent $error={!!error} disabled={props.disabled} className={`form-group ${props?.className ?? ""}`}>
            {label ? (
                <label htmlFor={name}>
                    <span>{label} {required ? " *" : ""}</span>
                </label>
            ) : (
                ""
            )}
            <input
                {...props}
                name={name}
                id={name}
                type="text"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                maxLength={maxLength}
            />
            <div>{description ? <small>{description}</small> : ""}</div>
            {error ? (
                <span className="error">{error}</span>
            ) : (
                ""
            )}
        </ContainerComponent>
    );
};

export default TextInput;
