import React from "react";
import styled from "styled-components";

const TitleContainer = styled.div<{ color?: string }>`
    margin: 8px 0;

    h1 {
        font-size: 2rem;
        ${({ color }) => (color ? `color: ${color};` : "")}
    }

    h2 {
        font-size: 1.5rem;
        ${({ color }) => (color ? `color: ${color};` : "")}
    }

    h3 {
        font-size: 1.25rem;
        ${({ color }) => (color ? `color: ${color};` : "")}
    }

    h4 {
        font-size: 1rem;
        ${({ color }) => (color ? `color: ${color};` : "")}
    }
`;

const InnerTitle = styled.h1<{ $featured?: boolean; }>`
    font-family: Inter, sans-serif;
    color: ${({ $featured, theme }) => ($featured ? theme.color.primary.background : "#2e2e2e")};
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
        position: relative;
        top: 3px;
        margin-right: 8px;
    }
`;

type TitleProps = {
    level: "1" | "2" | "3" | "4";
    featured?: boolean;
    titleStyle?: React.CSSProperties;
    icon?: any;
    actions?: any;
    [key: string]: any;
};

const Title: React.FC<TitleProps> = ({
    children,
    level = "2",
    icon = null,
    featured = false,
    titleStyle = {},
    actions = null,
    ...props
}) => {
    return (
        <TitleContainer {...props}>
            <InnerTitle $featured={featured} as={`h${level}`} style={titleStyle}>
                <span style={{ width: actions ? "70%" : "100%" }}>
                    {icon ? <span className="icon">{icon}</span> : ""}
                    {children}
                </span>
                {actions}
            </InnerTitle>
        </TitleContainer>
    );
};

export default Title;
