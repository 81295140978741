import api from "./api";

const Password = {
    async getPassword(email) {
        return await api.get(`/v1/business/customer/password/request?email=${email}`);
    },

    async setPassword(data) {
        return await api.patch(`/v1/business/customer/password/change`, data);
    },
};

export default Password;
