import styled from "styled-components";

const Actions = styled.div`
    button {
        background-color: black;
        font-size: 14px;
        text-transform: uppercase;
        width: 50%;
        padding: 8px;
        color: #bcbcbc;
    }

    button:first-of-type {
        background-color: rgba(0, 0, 0, 0.85);
    }
`;

export default Actions;