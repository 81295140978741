import styled from 'styled-components'
import theme from '~/styles/theme'

export const Content = styled.div`
max-width: 33.75rem;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;

    img {
        width: 17rem;
        margin-top: 2rem;
    }

    h1 {
        margin-top:1rem;
        margin-bottom:2rem;
        color: ${theme.color.secondary.background}
    }

    .separete-bar {
        display: flex;
        align-items: center;
        margin: 2.125rem 0;
        width: 100%;

        p{
            padding: 0 1.1rem;
            font-size: 1rem;
            line-height: 1.5rem;
            color: ${theme.color.baseLighter}
        }

        .bar {
            width: 100%;
            height: 1px;
            border: 1px solid ${theme.color.baseLighter};
        }
    }

    .forgot-button{
        text-align: center;

        button {
            background: none;
            cursor: pointer;
            padding: 1rem 0 ;
            color: ${theme.color.primary.background};
            text-decoration: underline;
        }
    }

`

export const FormContainer = styled.div`
width: 100%;
background: #ffffff;

padding: 1rem;
margin: 0 auto;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 1rem;

    p {
        font-size: 0.875rem;
        color: ${theme.color.danger.background}
    }
`

export const ModalContent = styled.div`
    margin: 0 auto;
    padding: 1rem;
    text-align: center;

    p{
        margin: 1.875rem 0 ;
    }

    button {
        margin: 1.875rem 0;
    }

    span {
        font-size: 0.875rem;
        color: ${theme.color.baseLighter};
    }

    strong {
        font-size: 0.75rem;
        color: ${theme.color.danger.background}
    }
`
