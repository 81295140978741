import React from "react";
import styled from "styled-components";
import Actions from "./Actions";
import Header from "./Header";

const getSize = (size: string) => {
    if (size === "full") {
        return "100%";
    }

    if (size === "big") {
        return "90%";
    }

    if (size === "small") {
        return "30%";
    }

    return "50%";
};

const Backdrop = styled.div`
    display: none;
    &.open {
        display: block;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 150vh;
        z-index: 50;
    }
`;

const Modal = styled.div<{ top?: string; size?: string }>`
    display: none;
    border: none;
    position: absolute;
    top: ${({ top }) => (top !== undefined ? top : "80px")};
    left: 50%;
    z-index: 100;
    width: ${({ size }) => getSize(size)};
    transform: translateX(-50%);
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    min-height: 150px;
    ${({ size }) => (size === "full" ? "height: 100%;" : "")}
    ${({ size }) => (size === "full" ? "overflow-y: auto;" : "")}

    &.open {
        display: block;
    }

    @media (max-width: 720px) {
        width: 95%;
    }
`;

type SimpleModalProps = {
    show: boolean;
    closeFn: () => void;
    top?: string;
    size?: string;
    children: any;
};

const SimpleModal: React.FC<SimpleModalProps> & { Actions?: any; Header?: any; Body?: any; } = ({ show, closeFn, children, top, size }) => {
    return (
        <>
            {show ? (
                <>
                    <Backdrop onClick={closeFn} className={show ? "open" : null} />
                    <Modal size={size} className={show ? "open" : null} top={size === "full" ? "0" : top}>
                        <div className="content">
                            <div className="dialog">
                                <div>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            ) : ""}
        </>
    );
};

SimpleModal.Actions = Actions;
SimpleModal.Header = Header;
SimpleModal.Body = ({ children }) => {
    return (
        <div style={{ padding: "10px 17px" }}>
            {children}
        </div>
    )
};

export default SimpleModal;
export { Actions, Header };
